// @flow
import {
    randomString,
    xmlFileParser,
    formatDimensionString,
} from 'shared/helpers';
import {getProductByCode, getProductConfig} from 'service';


export const contentParser = async (
    content,
    jobId,
    roomId,
    genericMessageHandler,
    notify,
) => {
    const cabinetData = [];
    let j = 0;
    const dialogString = [];
    try {
        const xmlDocument = await xmlFileParser(content);
        const extractedData =
            xmlDocument['document']['products'][0]['cabinets'][0]['cabinet'];

        for (let i = 0; i < extractedData.length; i++) {
            if(typeof extractedData[i]['base'][0]['model_no'] === 'undefined') {
               return;
            } else {
                const code = extractedData[i]['base'][0]['model_no'][0];

                const drawerData =
                    xmlDocument['document']['products'][0]['cabinets'][0][
                        'cabinet'
                        ][i]['dooranddrawers'][0]['drawentity'];
                const dimensions =
                    extractedData[i]['base'][0]['size'][0].split(',');
                const position =
                    extractedData[i]['base'][0]['position'][0].split(',');
                const individualProduct = await getProductByCode(code);
                const name = extractedData[i]['base'][0]['name'][0].replace(
                    /;/g,
                    ' | '
                );
                const carcasePanels = extractedData[i]['carcasspanels'][0]['board'];
                const drawerDetails = drawerData
                    ? getDrawerDetails(dimensions, position, drawerData)
                    : [];
                let shelfCount = 0;
                const shelfOffset = [];
                const shelfType = [];
                const shelfPosition = [];
                const shelfStyle = [];
                const shelves = [];
                if (carcasePanels) {
                    carcasePanels.forEach((carcasePanel) => {
                        if (carcasePanel['name'][0].includes('Shelf')) {
                            shelfType[shelfCount] = 5;
                            shelfStyle[shelfCount] = 1;
                            shelfPosition[shelfCount] =
                                carcasePanel['position'][0].split(',')[2] -
                                position[2];
                            shelfOffset[shelfCount] = 5;
                            shelves[shelfCount] = {
                                shelf_type: shelfType[shelfCount],
                                shelf_style: shelfStyle[shelfCount],
                                shelf_position: shelfPosition[shelfCount],
                                shelf_offset: shelfOffset[shelfCount],
                            };
                            shelfCount++;
                        }
                    });
                }
                if (individualProduct) {
                    const id = randomString(7);
                    const dimension = {
                        height: parseInt(dimensions[2]),
                        width: parseInt(dimensions[0]),
                        depth: parseInt(dimensions[1]),
                    };
                    const productConfig = await getProductConfig(
                        individualProduct.id
                    );
                    const dimensionString = formatDimensionString(
                        productConfig,
                        dimension
                    );
                    cabinetData.push({
                        name,
                        width: dimension['width'],
                        depth: dimension['depth'],
                        height: dimension['height'],
                        image:
                            '/uploads/cabinet_images/' +
                            individualProduct.image,
                        changedImage: individualProduct.changedImage
                            ? '/' + individualProduct.changedImage
                            : null,
                        code: extractedData[i]['base'][0]['model_no'][0],
                        isAvailable: true,
                        isAdded: false,
                        isValidated: true,
                        isBenchtop:
                            name.toLowerCase() == 'worktop' ? true : false,
                        jobId: jobId,
                        roomId: roomId,
                        type: individualProduct.id,
                        shelves: shelves.sort(
                            (a, b) => a.shelf_position - b.shelf_position
                        ),
                        shelfType: shelfType,
                        shelfPosition: shelfPosition.sort(function (a, b) {
                            return a - b;
                        }),
                        shelfOffset: shelfOffset,
                        shelfStyle: shelfStyle,
                        shelfCount: shelfCount,
                        dimensionString: dimensionString,
                        drawerDetails: drawerDetails,
                        id,
                    });
                } else {
                    j++;
                    const width = parseInt(dimensions[0]);
                    const depth = parseInt(dimensions[1]);
                    const height = parseInt(dimensions[2]);

                    dialogString.push(j + ' - ' + name);

                    cabinetData.push({
                        name,
                        width,
                        depth,
                        height,
                        code: extractedData[i]['base'][0]['model_no'][0],
                        isAvailable: false,
                        isAdded: false,
                        isBenchtop: name.toLowerCase() == 'worktop' ? true : false,
                        jobId: jobId,
                        roomId: roomId,
                        id: randomString(7),
                        shelves: shelves.sort(
                            (a, b) => a.shelf_position - b.shelf_position
                        ),
                        shelfType: shelfType,
                        shelfPosition: shelfPosition.sort(function (a, b) {
                            return a - b;
                        }),
                        shelfOffset: shelfOffset,
                        shelfStyle: shelfStyle,
                        shelfCount: shelfCount,
                        drawerDetails: drawerDetails,
                        dimensionString: `${height} x ${width} x ${depth}`,
                    });
                }
            }
        }
    } catch (e) {
        throw new Error();
    }

    return {cabinetData, dialogString};
};

const getDrawerDetails = (cabinetDimension, cabinetPosition, drawerData) => {
    const drawerFacePositions = [];
    const drawerFaceHeights = [];
    let drawerDetails = {};

    for (let i = 0; i < drawerData.length; i++) {
        const drawerPosition = drawerData[i]['base'][0]['position'][0];
        const drawerDimension = drawerData[i]['base'][0]['size'][0];
        drawerFacePositions[i] = parseFloat(drawerPosition.split(',')[2]);
        drawerFaceHeights[i] = parseFloat(drawerDimension.split(',')[1]);
    }

    const bottomMargin =
        drawerFacePositions[drawerFacePositions.length - 1] -
        cabinetPosition[2];
    const topMargin =
        parseFloat(cabinetDimension[2]) +
        parseFloat(cabinetPosition[2]) -
        (drawerFacePositions[0] + drawerFaceHeights[0]);
    const drawerGap =
        drawerFacePositions[0] -
        (drawerFacePositions[1] + drawerFaceHeights[1]);

    drawerDetails = {
        faceDetails: drawerFaceHeights,
        topMargin: topMargin,
        bottomMargin: bottomMargin,
        drawerGap: drawerGap,
    };

    return drawerDetails;
};
